@import url('https://fonts.googleapis.com/css2?family=Imprima&family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');
.clientsWorkSection {
    font-family: 'Sodo Sans', sans-serif;
    margin-top:80px;
    /* margin-left:60px; */
}



.image-wrapper {
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 20px;
}

.clients-work-image {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s;
}

.clients-work-image:hover {
    transform: scale(1.05);
}
.clientsWorkSection h2{
    font-family: 'Sodo Sans', sans-serif;
    margin-bottom: 40px;
    font-size: 2.5rem;
    word-spacing: 2px;
    text-align:left;
    margin-top:50px;
    font-weight: 500;
    text-decoration: none !important;
    font-family: 'Sodo Sans', sans-serif;
}


.img-fluid {
   
top: 2533px;
margin-right: 60px;

gap: 30px;
opacity: 0.53px;
    margin-top:100px;
    width: 100%;
    height: auto;
  }

  
  
  .mb-3 {

    margin-bottom: 20px !important;
  }
  
  .col-md-4 img {
    flex-grow: 1;
  }
  
  .col-md-4 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap:20px;
  }

