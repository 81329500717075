@import url('https://fonts.googleapis.com/css2?family=Imprima&family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Imprima&family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');


@media (max-width: 768px) {
    .testimonial-cards {
      padding: 1rem; /* Adjust padding for mobile */
    }
    .testimonial-quote {
      font-size: 1.5rem; /* Adjust font size for better readability */
    }
    .client-name {
      font-size: 1.5rem; /* Adjust client name size */
    }
    .client-title {
      font-size: 1rem; /* Adjust title size */
    }
  }
  
