/* input[type="text"], input[type="email"], input[type="tel"], textarea {
  font-family: 'Sodo Sans', sans-serif;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-sizing: border-box;
  width: 50px;
} */

.faq-container {
  font-family: 'Sodo Sans', sans-serif;
    font-weight: 700; /* Bold weight */
  
    width:100%;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
.faq{
  font-family: 'Sodo Sans', sans-serif;
    font-weight: 700; /* Bold weight */
  
  padding:20px;
  background: #F3F3F3;

  
}
.faq-search{
  font-family: 'Sodo Sans', sans-serif;
    width:100px;
  }
  
  .faq-list {
    font-family: 'Sodo Sans', sans-serif;
    text-align: left;
  }
  
  .faq-item {
    
    margin-bottom: 20px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .faq-question {
    font-family: 'Sodo Sans', sans-serif;
    font-weight: 700; /* Bold weight */
    
    font-size: 18px;
    cursor: pointer;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
  }
  
  .faq-answer {
    
    padding: 10px 0;
  }
  
  .faq-answer p {
    font-family: 'Sodo Sans', sans-serif;
    font-weight: 700; /* Bold weight */
    margin: 0;
    font-size: 16px;
    color: #333;
  }
  