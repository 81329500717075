@import url('https://fonts.googleapis.com/css2?family=Inria+Serif:wght@300;400;700&display=swap');


.container {
    position: relative;
    height: 900px;
    width: 100%;
    overflow: hidden;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: -1;
    opacity: 0.3;
}

.carouselWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    width: 600px;
    /* z-index: 2; */
}

.carousel {
    position: absolute;
    width: 600px;
    height: 600px;
    transition: transform 1s ease-in-out;
    margin-left: 1900px;
    margin-top: 1250px;
}

.circle {
    position: absolute;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.largeCircle {
    width: 1050px;
    height: 1050px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(15deg) !important; 
   
}








.smallCircle {
    width: 600px;
    height: 600px;
    z-index: 2;
    transform-origin: center; 
    transition: transform 1s ease-in-out; 
}
.smallCircle img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: rotate(0deg) !important;
}
.pos0 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(40deg) translate(0, -600px) rotate(-40deg);
    box-shadow:  5px 5px 5px black !important;
    text-align:center;

}

.pos1 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(112deg) translate(0, -600px) rotate(-112deg);
    box-shadow:  5px 5px 5px black !important;
}

.pos2 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(184deg) translate(0, -600px) rotate(-184deg);
    box-shadow:  5px 5px 5px black !important;
}

.pos3 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(256deg) translate(0, -600px) rotate(-256deg);
    box-shadow:  5px 5px 5px black !important;
}

.pos4 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(328deg) translate(0, -600px) rotate(-328deg);
    box-shadow:  5px 5px 5px black !important;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}

.quarterCircle {
    margin-top: 130px;
    width: 400px;
    height: 400px;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF !important;
    z-index: 100;
    border: 15px solid #372113;
}

.textWrapper {
    position: absolute;
    bottom: 20px;
    left: 60% !important;
    transform: translateX(-50%);
    display: flex;
    white-space: nowrap; 
    text-align: center;
    gap: 40px;
}

.text {
    padding: 10px ;
    background: none;
    color: black;
    font-weight: 400;
}


